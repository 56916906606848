const getRuntimeConfig = require('@nuuly-ui/core-lib/config/config');

// This config module is the main location for application-level configuration.
// Because this module gathers a lot of it's information from environment variables
// and information only known on the server, we export it and render it into a
// meta tag in the initial HTML render and when accessing this module client
// side, we parse it from the meta tag.  This allows us to avoid any node
// polyfills in our client code and avoids needing to specify EnvironmentPlugin
// values for he vast majority of our environment variables in our webpack
// builds

const rentConfig = {
    // Urls for API calls
    apiUrls: {
        boxService: process.env.NUU_BOX_SERVICE_API_URL,
        catalogService: process.env.NUU_CATALOG_SERVICE_API_URL,
        chatService: process.env.NUU_CHAT_SERVICE_API_URL,
        closetService: process.env.NUU_CLOSET_SERVICE_API_URL,
        giftCardsService: process.env.NUU_GIFT_CARDS_SERVICE_API_URL,
        imagesService: process.env.NUU_IMAGES_SERVICE_API_URL,
        inventoryService: process.env.NUU_INVENTORY_WEB_SOCKET_SERVICE_API_URL,
        issueService: process.env.NUU_ISSUE_SERVICE_API_URL,
        productService: process.env.NUU_PRODUCT_SERVICE_API_URL,
        productServiceURBNSearch: process.env.NUU_PRODUCT_SERVICE_V2_API_URL,
        reviewServiceV2: process.env.NUU_REVIEW_SERVICE_API_URL_V2,
        shippingService: process.env.NUU_SHIPPING_SERVICE_API_URL,
        subscriptionService: process.env.NUU_SUBSCRIPTION_SERVICE_API_URL,
    },
    // Friendbuy
    friendbuy: {
        // Merchant ID
        merchantId: process.env.NUU_FRIENDBUY_MERCHANT_ID,

        // Share Widget ID
        shareWidgetId: process.env.NUU_FRIENDBUY_SHARE_WIDGET_ID,

        // Email Capture Widget ID
        emailCaptureWidgetId: process.env.NUU_FRIENDBUY_EMAIL_CAPTURE_WIDGET_ID,

        // Employee Share Widget ID
        employeeShareWidgetId: process.env.NUU_FRIENDBUY_EMPLOYEE_SHARE_WIDGET_ID,

        // Influencer Email Capture Widget ID
        influencerEmailCaptureWidgetId: process.env.NUU_FRIENDBUY_INFLUENCER_EMAIL_CAPTURE_WIDGET_ID,
    },

    // Intercom App ID
    intercomAppId: process.env.NUU_INTERCOM_APP_ID,

    // Recurly fraud enabled
    recurlyFraudEnabled: (process.env.NUU_RECURLY_FRAUD_ENABLED === 'true'),

    // Recurly Public API key
    recurlyPublicApiKey: process.env.NUU_RECURLY_PUBLIC_API_KEY,
};

const runtimeConfig = getRuntimeConfig(rentConfig);

module.exports = runtimeConfig;
