import runtimeConfig from '~config/config';

import useBraze from '~coreModules/braze/js/composables/use-braze';

import createRentBrazeStore, {
    BRAZE_MODULE_NAME,
    INIT_RENT_BRAZE,
} from '~modules/braze/js/braze-store';

export default function useRentBraze({ isCreatingAccount, brazeInitCallback, store } = {}) {
    const { loggedIn } = store.getters;
    const brazeEnabled = runtimeConfig.features.brazeSdk;

    function handleBrazeInitialization() {
        store.registerModule(BRAZE_MODULE_NAME, createRentBrazeStore());

        if (!isCreatingAccount) {
            store.dispatch(`${BRAZE_MODULE_NAME}/${INIT_RENT_BRAZE}`);
        }

        brazeInitCallback?.();
    }

    useBraze({ loggedIn, brazeEnabled, handleBrazeInitialization });
}
