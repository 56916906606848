import runtimeConfig from '~config/config';

const { apiUrls } = runtimeConfig;

/**
 * Adds a product to the user's box
 * @param {Object} r15Svc - r15 axios REST client instance
 * @param {String} sku - the SKU of the product to be added to the user's box
 */
export function addToBox(r15Svc, sku) {
    return r15Svc.post(`${apiUrls.boxService}/sku/add`, { skuId: sku });
}

/**
 * Edit's a product in the user's box
 * @param {Object} r15Svc - r15 axios REST client instance
 * @param {String} oldSku - the SKU of the product to be removed from the user's box
 * @param {String} newSku - the SKU of the product to be added to the user's box
 */
export function editBoxItem(r15Svc, oldSku, newSku) {
    return r15Svc.post(`${apiUrls.boxService}/sku/edit`, { oldSku, newSku });
}

/**
 * Removes a product from the user's box
 * @param {Object} r15Svc - r15 axios REST client instance
 * @param {String} sku - the SKU of the product to be removed from the user's box
 */
export function deleteFromBox(r15Svc, sku) {
    return r15Svc.delete(`${apiUrls.boxService}/sku/${sku}`);
}

/**
 * Gets the user's box
 * @param {Object} r15Svc - r15 axios REST client instance
 */
export function getBox(r15Svc) {
    return r15Svc.get(`${apiUrls.boxService}/get`);
}

/**
 * Submits request to ship user's box
 * @param {Object} r15Svc - r15 axios REST client instance
 */
export function submitBoxShipment(r15Svc) {
    return r15Svc.post(`${apiUrls.boxService}/order/submit`);
}

/**
 * Gets a user's order history
 * @param {Object} r15Svc - r15 axios REST client instance
 * @param {Number} year - the year for the users box history
 */
export function getOrderHistory(r15Svc, year) {
    return r15Svc.get(`${apiUrls.boxService}/order/history`, {
        params: {
            ...(year && {
                year,
            }),
        },
    });
}

/**
 * Preview the purchase of an item
 * @param {Object} r15Svc - r15 axios REST client instance
 * @param {String} skuId - the SKU of the product to be purchased
 * @param {String} boxId - the box that contains the skuId
 */
export function previewPurchaseItem(r15Svc, skuId, boxId, couponCode = '') {
    return r15Svc.post(`${apiUrls.boxService}/purchase/preview`, { skuId, boxId, couponCode });
}

/**
 * Preview charges or credits for a given box
 * @param {Object} r15Svc - r15 axios REST client instance
 * @param {String} skuId - the SKU of the product to be purchased
 * @param {String} boxId - the box that contains the skuId
 */
export function previewBoxOrder(r15Svc, boxId) {
    return r15Svc.post(`${apiUrls.boxService}/order/preview`, { boxId });
}

/**
 * Purchase an item
 * @param {Object} r15Svc - r15 axios REST client instance
 * @param {String} skuId - the SKU of the product to be purchased
 * @param {String} boxId - the box that contains the skuId
 */
export function purchaseItem(r15Svc, skuId, boxId, couponCode = '') {
    return r15Svc.post(`${apiUrls.boxService}/purchase`, { skuId, boxId, couponCode });
}

/**
 * Retrieves a return shipping label
 * @param {Object} r15Svc - r15 axios REST client instance
 * @param {String} orderId - the id of order
 * @return {Promise} - service request
 */
export function getReturnShippingLabel(r15Svc, orderId) {
    return r15Svc.get(`${apiUrls.boxService}/order/${orderId}/labels/reprint`);
}

/**
 * Checks if order has been dropped off
 * @param {Object} r15Svc - r15 axios REST client instance
 * @return {Promise} - service request
 */
export function checkForDropOff(r15Svc) {
    return r15Svc.post(`${apiUrls.shippingService}/update/force`);
}

/**
 * Checks if a user's is eligible for a refund
 * @param {Object} r15Svc - r15 axios REST client instance
 * @return {Promise} - service request
 */
export function checkRefundEligibility(r15Svc) {
    return r15Svc.get(`${apiUrls.boxService}/refund/eligibility`);
}

/**
 * Issues a refund to a user
 * @param {Object} r15Svc - r15 axios REST client instance
 * @return {Promise} - service request
 */
export function initiateRefund(r15Svc) {
    return r15Svc.post(`${apiUrls.boxService}/refund/execute`);
}

/**
 * Returns pre purchase for a sku
 * @param {Object} r15Svc - r15 axios REST client instance
 * @param  {Object} options - Options object
 * @param  {String} options.skuId - skuId of item being purchased
 * @param  {String} options.couponCode - couponCode to apply to purchase
 * @param  {String} options.giftCardCode - giftCardCode to apply to purchase
 * @return {Promise} - service request
 */
export function previewPrePurchase(r15Svc, { skuId, couponCode = '', giftCardCode = '' }) {
    return r15Svc.post(`${apiUrls.boxService}/prepurchase/preview`, {
        skuId,
        couponCode,
        giftCardCode,
    });
}

/**
 * Purchase a prepurchase item
 * @param {Object} r15Svc - r15 axios REST client instance
 * @param  {Object} options - Options object
 * @param  {String} options.skuId - skuId of item being purchased
 * @param  {String} options.couponCode - couponCode to apply to purchase
 * @param  {String} options.giftCardCode - giftCardCode to apply to purchase
 * @return {Promise} - service request
 */
export function purchasePrePurchaseItem(r15Svc, { skuId, couponCode = '', giftCardCode = '' }) {
    return r15Svc.post(`${apiUrls.boxService}/prepurchase/purchase`, {
        skuId,
        couponCode,
        giftCardCode,
    });
}

/**
 * Returns open pre purchases
 * @param {Object} r15Svc - r15 axios REST client instance
 * @return {Promise} - service request
 */
export function getPrePurchases(r15Svc) {
    return r15Svc.get(`${apiUrls.boxService}/prepurchase`);
}
