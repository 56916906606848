import { get, isEmpty, head } from 'lodash-es';

import { GLOBAL_EVENT, SET_404_ERROR } from '~coreModules/core/js/store';

import { PROFILE_MODULE_NAME } from '~modules/profile/js/profile-store';
import { MODALS_MODULE_NAME, LAUNCH_MODAL, POP_MODALS } from '~coreModules/modals/js/modals-store';
import { MODALS } from '~modules/modals/js/modal-constants';
import { CATALOG_QUERY_BOX_CONTEXT } from '~modules/catalog/js/catalog-constants';

import { NUU_COOKIE_BONUS_ITEM_COUNTER } from '~modules/core/js/cookie-constants';
import {
    BOX_ITEM_STATUS_UPDATED,
    BOX_ITEM_OUT_OF_STOCK,
    REQUEST_BOX,
    CHECK_CARRIER_STATUS,
    CHECK_CARRIER_SUCCESS,
    CHECK_CARRIER_FAILURE,
} from '~modules/core/js/global-event-constants';
import {
    BOX_MIN_ITEM_LIMIT_DEFAULT,
    BOX_MAX_ITEM_LIMIT_DEFAULT,
    BOX_ORDER_STATUSES,
    ORDERED_BOX_STATUSES,
    ORDER_ITEM_STATUSES,
    NUU_COOKIE_BONUS_ITEM_COUNTER_EXPIRY,
    RETURNABLE_BOX_ORDER_STATUSES,
    RETURN_BOX_ORDER_STATUSES,
} from '~modules/box/js/box-constants';
import { updateBonusItemCount, findRecentlyOrderedProductBySku } from '~modules/box/js/box-utils';
import {
    addToBox,
    deleteFromBox,
    editBoxItem,
    getBox,
    getOrderHistory,
    submitBoxShipment,
    checkForDropOff,
    previewBoxOrder,
    getPrePurchases,
} from '~modules/box/js/box-api';
import { SUBSCRIPTION_STATUSES } from '~modules/profile/js/profile-constants';
import { CART_EVENTS } from '~modules/analytics/js/analytics-constants';

export const BOX_MODULE_NAME = 'box';

export const SET_BOX_DATA = 'SET_BOX_DATA';
export const ADD_BOX_ITEM = 'ADD_BOX_ITEM';
export const DELETE_BOX_ITEM = 'DELETE_BOX_ITEM';
export const SET_ORDER_HISTORY = 'SET_ORDER_HISTORY';
export const UPDATE_BONUS_ITEM_COUNT = 'UPDATE_BONUS_ITEM_COUNT';
export const SET_BONUS_ITEM_COUNT = 'SET_BONUS_ITEM_COUNT';
export const SET_ADDITIONAL_BONUS_ITEM = 'SET_ADDITIONAL_BONUS_ITEM';
export const REMOVE_BONUS_ITEM_COUNT = 'REMOVE_BONUS_ITEM_COUNT';
export const UPDATE_BOX_ITEM_QUANTITY = 'UPDATE_BOX_ITEM_QUANTITY';
export const SET_ORDER_HISTORY_ITEM_TO_PURCHASED = 'SET_ORDER_HISTORY_ITEM_TO_PURCHASED';
export const UPDATE_RENEW_EARLY_DATA = 'UPDATE_RENEW_EARLY_DATA';
export const FETCH_BOX_DATA = 'FETCH_BOX_DATA';
export const FETCH_UPCOMING_PRE_PURCHASES = 'FETCH_UPCOMING_PRE_PURCHASES';
export const SSR_FETCH_BOX_DATA = 'SSR_FETCH_BOX_DATA';
export const EDIT_BOX_ITEM = 'EDIT_BOX_ITEM';
export const SUBMIT_BOX_SHIPMENT = 'SUBMIT_BOX_SHIPMENT';
export const FETCH_ORDER_HISTORY = 'FETCH_ORDER_HISTORY';
export const CHECK_BOX_ITEM_QUANTITY = 'CHECK_BOX_ITEM_QUANTITY';
export const CHECK_FOR_DROP_OFF = 'CHECK_FOR_DROP_OFF';
export const SET_RECENT_ORDER_ITEM_TO_PURCHASED = 'SET_RECENT_ORDER_ITEM_TO_PURCHASED';
export const SET_OVERALL_RATING_TO_RECENT_REVIEWED_ITEM = 'SET_OVERALL_RATING_TO_RECENT_REVIEWED_ITEM';
export const PREVIEW_BOX_ORDER = 'PREVIEW_BOX_ORDER';
export const SET_BOX_ORDER_PREVIEW = 'SET_BOX_ORDER_PREVIEW';
export const SET_UPCOMING_PRE_PURCHASES_DATA = 'SET_UPCOMING_PRE_PURCHASES_DATA';

export default {
    namespaced: true,

    state: () => ({
        boxInfo: {},
        boxOrderStatus: '',
        openBox: {
            id: '',
            availableQuantity: 0,
            maxQuantity: '',
            minQuantity: '',
            products: [],
            profileId: '',
            skus: '',
            status: '',
            statusUpdatedAt: '',
        },
        recentOrder: {
            boxId: '',
            id: '',
            orderedAt: '',
            products: [],
            status: '',
            statusDetail: '',
            statusUpdatedAt: '',
            tracking: {},
            type: '',

            /* whether or not the user has reviewed all of the items in their most recent order;
            only true when the user has reviewed all items in the recent order and has not yet SSR'd.
            Used to control visibility of the Recent Order tray */
            hasReviewedAllRecentlyOrdered: false,
        },
        orderHistory: [],
        bonusItemCounter: {
            count: 0,
        },
        additionalChargeBonusItem: {},
        upcomingPrePurchases: [],
    }),

    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_BOX_DATA](state, {
            boxInfo,
            boxOrderStatus,
            openBox,
            recentOrder,
        }) {
            state.boxInfo = boxInfo || {};
            state.boxOrderStatus = boxOrderStatus || '';
            state.openBox = openBox || {};
            state.recentOrder = recentOrder ?
                {
                    hasReviewedAllRecentlyOrdered: state.recentOrder.hasReviewedAllRecentlyOrdered,
                    ...recentOrder,
                } : {};
        },
        [SET_UPCOMING_PRE_PURCHASES_DATA](state, { prePurchases }) {
            state.upcomingPrePurchases = prePurchases;
        },
        [SET_ORDER_HISTORY](state, newHistory) {
            state.orderHistory = newHistory.customerOrders;
        },
        [UPDATE_BONUS_ITEM_COUNT](state, {
            defaultBonusItemCount,
            userStoredBonusItemCount,
            maxBonusItemCount,
            minBonusItemCount,
        }) {
            state.bonusItemCounter.count = updateBonusItemCount(
                defaultBonusItemCount,
                userStoredBonusItemCount,
                maxBonusItemCount,
                minBonusItemCount,
            );
        },
        [UPDATE_RENEW_EARLY_DATA](state, {
            boxBalance,
            boxOrderStatus,
        }) {
            state.boxOrderStatus = boxOrderStatus;
            state.boxInfo.boxBalance = boxBalance;
        },
        [SET_BONUS_ITEM_COUNT](state, count) {
            state.bonusItemCounter.count = count;
            this.$cookies.set(NUU_COOKIE_BONUS_ITEM_COUNTER, count,
                { maxAge: NUU_COOKIE_BONUS_ITEM_COUNTER_EXPIRY });
        },
        [REMOVE_BONUS_ITEM_COUNT](state) {
            state.bonusItemCounter.count = 0;

            if (process.env.VUE_ENV === 'client') {
                this.$cookies.remove(NUU_COOKIE_BONUS_ITEM_COUNTER);
            }
        },
        [UPDATE_BOX_ITEM_QUANTITY](state, { skuId, availableToRent }) {
            const productToUpdate = state.openBox.products.find(product => product.skuId === skuId);
            productToUpdate.availableToRent = availableToRent;
        },
        [SET_ORDER_HISTORY_ITEM_TO_PURCHASED](state, { boxId, skuId }) {
            const orderHistoryIndex = state.orderHistory.findIndex(order => order.boxId === boxId);

            state.orderHistory[orderHistoryIndex].products.forEach((product) => {
                if (product.skuId === skuId) {
                    product.orderData.status = ORDER_ITEM_STATUSES.PURCHASED;
                }
            });
        },
        [SET_RECENT_ORDER_ITEM_TO_PURCHASED](state, skuId) {
            const productToUpdate = findRecentlyOrderedProductBySku(state, skuId);

            if (productToUpdate) {
                productToUpdate.orderData.status = ORDER_ITEM_STATUSES.PURCHASED;
            }
        },
        [SET_OVERALL_RATING_TO_RECENT_REVIEWED_ITEM](state, { skuId, overallRating }) {
            const productToUpdateIndex =
                state.recentOrder.products.findIndex(product => get(product, 'skuId') === skuId);
            const product = state.recentOrder.products[productToUpdateIndex];

            if (product) {
                product.overallRating = overallRating;
                state.recentOrder.products[productToUpdateIndex] = product;
            }

            const numberOfUnreviewed = state.recentOrder.products.filter(_product => !_product.overallRating).length;
            if (numberOfUnreviewed === 0) {
                state.recentOrder.hasReviewedAllRecentlyOrdered = true;
            }
        },
        [SET_ADDITIONAL_BONUS_ITEM](state, additionalChargeBonusItem) {
            state.additionalChargeBonusItem = additionalChargeBonusItem;
        },
        [SET_BOX_ORDER_PREVIEW](state, boxOrderPreview) {
            state.boxOrderPreview = boxOrderPreview;
        },
    },

    /* eslint-enable no-param-reassign */
    actions: {
        [FETCH_BOX_DATA]({ state, commit, getters }) {
            if (getters.disableBoxActions) return Promise.resolve();

            return getBox(this.$r15Svc)
                .then((res) => {
                    commit(SET_BOX_DATA, {
                        boxInfo: res.boxInfo,
                        boxOrderStatus: res.boxOrderStatus,
                        openBox: res.openBox,
                        recentOrder: res.recentOrder,
                    });

                    if (!getters.boxIsOpen) {
                        commit(REMOVE_BONUS_ITEM_COUNT);
                    } else if (getters.boxIsOpen && process.env.VUE_ENV === 'client') {
                        commit(UPDATE_BONUS_ITEM_COUNT, {
                            userStoredBonusItemCount: state.bonusItemCounter.count,
                            defaultBonusItemCount: getters.defaultBonusItemCount,
                            maxBonusItemCount: getters.maxBonusItemCount,
                            minBonusItemCount: getters.minBonusItemCount,
                        });
                    }
                })
                .catch(() => {
                    this.$logger.debugError('Failed to fetch the user\'s box');
                });
        },
        // eslint-disable-next-line consistent-return
        async [FETCH_UPCOMING_PRE_PURCHASES]({ commit, rootState }) {
            const { subscriptionStatus } = rootState[PROFILE_MODULE_NAME];
            if (subscriptionStatus !== SUBSCRIPTION_STATUSES.subscriber) return Promise.resolve();

            try {
                const res = await getPrePurchases(this.$r15Svc);
                if (res) {
                    commit(SET_UPCOMING_PRE_PURCHASES_DATA, {
                        prePurchases: res?.prePurchases,
                    });
                }
            } catch (error) {
                this.$logger.debugError('Failed to fetch the user\'s upcoming pre purchases', error);
            }
        },
        [SSR_FETCH_BOX_DATA]({
            commit,
            dispatch,
            getters,
        }, { requestCookies = {} }) {
            if (getters.disableBoxActions) return Promise.resolve();

            const userStoredBonusItemCount = get(requestCookies, NUU_COOKIE_BONUS_ITEM_COUNTER);

            return dispatch(FETCH_BOX_DATA)
                .then(() => commit(UPDATE_BONUS_ITEM_COUNT, {
                    userStoredBonusItemCount,
                    defaultBonusItemCount: getters.defaultBonusItemCount,
                    maxBonusItemCount: getters.maxBonusItemCount,
                    minBonusItemCount: getters.minBonusItemCount,
                }))
                .catch(error => Promise.reject(error));
        },
        [SUBMIT_BOX_SHIPMENT]({ commit, dispatch, state }) {
            const boxId = state.openBox.id;

            return submitBoxShipment(this.$r15Svc)
                .then((res) => {
                    commit(SET_BOX_DATA, {
                        boxInfo: res.boxInfo,
                        boxOrderStatus: res.boxOrderStatus,
                        openBox: res.openBox,
                        recentOrder: res.recentOrder,
                    });

                    dispatch(GLOBAL_EVENT, {
                        type: REQUEST_BOX,
                        data: { boxId },
                    }, { root: true });
                })
                .catch((error) => {
                    this.$logger.debugError('Failed to submit the user\'s box');
                    return Promise.reject(error);
                });
        },
        [DELETE_BOX_ITEM]({ commit, dispatch }, {
            sku,
            productAnalyticsData,
        }) {
            return deleteFromBox(this.$r15Svc, sku)
                .then((res) => {
                    commit(SET_BOX_DATA, {
                        boxInfo: res.boxInfo,
                        boxOrderStatus: res.boxOrderStatus,
                        openBox: res.openBox,
                        recentOrder: res.recentOrder,
                    });
                    commit(SET_ADDITIONAL_BONUS_ITEM, {});
                })
                .then(() => {
                    dispatch(GLOBAL_EVENT, {
                        type: BOX_ITEM_STATUS_UPDATED,
                        data: {
                            action: CART_EVENTS.DELETE_BOX_ITEM,
                            productAnalyticsData,
                        },
                    }, { root: true });
                })
                .catch((error) => {
                    this.$logger.debugError('Failed to delete a user\'s box item');
                    return Promise.reject(error);
                });
        },
        [EDIT_BOX_ITEM]({ commit, dispatch }, {
            oldSku,
            sku,
            productAnalyticsData,
        }) {
            return editBoxItem(this.$r15Svc, oldSku, sku)
                .then((res) => {
                    commit(SET_BOX_DATA, {
                        boxOrderStatus: res.boxOrderStatus,
                        openBox: res.openBox,
                        recentOrder: res.recentOrder,
                        boxInfo: res.boxInfo,
                    });
                })
                .then(() => {
                    dispatch(GLOBAL_EVENT, {
                        type: BOX_ITEM_STATUS_UPDATED,
                        data: {
                            action: CART_EVENTS.UPDATE_BOX_ITEM,
                            productAnalyticsData: {
                                ...productAnalyticsData,
                                product: {
                                    ...productAnalyticsData.product,
                                    productVariant: sku,
                                },
                            },
                        },
                    }, { root: true });
                })
                .catch((error) => {
                    this.$logger.debugError('Failed to edit/update a user\'s box item');
                    return Promise.reject(error);
                });
        },
        [ADD_BOX_ITEM]({
            commit,
            dispatch,
            getters,
            state,
        }, {
            sku,
            productAnalyticsData,
        }) {
            return addToBox(this.$r15Svc, sku)
                .then((res) => {
                    commit(SET_BOX_DATA, {
                        boxInfo: res.boxInfo,
                        boxOrderStatus: res.boxOrderStatus,
                        openBox: res.openBox,
                        recentOrder: res.recentOrder,
                    });

                    const { currentBoxCounterMax, unusedFreeBonusItems } = getters;
                    const showBoxBonusItemSlideOut = state.openBox.products.length > currentBoxCounterMax &&
                        !unusedFreeBonusItems;

                    commit(UPDATE_BONUS_ITEM_COUNT, {
                        userStoredBonusItemCount: state.bonusItemCounter.count,
                        defaultBonusItemCount: getters.defaultBonusItemCount,
                        maxBonusItemCount: getters.maxBonusItemCount,
                        minBonusItemCount: getters.minBonusItemCount,
                    });

                    if (showBoxBonusItemSlideOut) {
                        const additionalChargeBonusItem = head(state.openBox.products.filter(e => e.skuId === sku));

                        commit(SET_ADDITIONAL_BONUS_ITEM, additionalChargeBonusItem);
                        dispatch(`${MODALS_MODULE_NAME}/${POP_MODALS}`, {}, { root: true }).then(() => {
                            dispatch(`${MODALS_MODULE_NAME}/${LAUNCH_MODAL}`, MODALS.boxBonusItemSlide, { root: true });
                        });
                    }
                })
                .then(() => {
                    dispatch(GLOBAL_EVENT, {
                        type: BOX_ITEM_STATUS_UPDATED,
                        data: {
                            action: CART_EVENTS.ADD_BOX_ITEM,
                            productAnalyticsData,
                        },
                    }, { root: true });
                })
                .catch((error) => {
                    this.$logger.debugError('Failed to add an item to the user\'s box');
                    return Promise.reject(error);
                });
        },
        [FETCH_ORDER_HISTORY]({ commit, rootGetters }, year) {
            if (rootGetters[`${PROFILE_MODULE_NAME}/isNonSubscriber`]) return Promise.resolve();

            return getOrderHistory(this.$r15Svc, year)
                .then(res => commit(SET_ORDER_HISTORY, res))
                .catch(() => {
                    const message = 'Failed to fetch the user\'s order history';
                    this.$logger.debugError(message);
                    commit(SET_404_ERROR, { message }, { root: true });
                });
        },
        [CHECK_BOX_ITEM_QUANTITY]({
            state,
            dispatch,
            commit,
            getters,
        }, skusToUpdate) {
            const skuIds = Object.keys(skusToUpdate);

            skuIds.forEach((skuId) => {
                const quantity = skusToUpdate[skuId];
                const productToUpdate = state.openBox.products.find(product => product.skuId === skuId);
                if (productToUpdate && productToUpdate.availableToRent && !quantity) {
                    commit(UPDATE_BOX_ITEM_QUANTITY, { skuId, availableToRent: false });

                    dispatch(GLOBAL_EVENT, {
                        type: BOX_ITEM_OUT_OF_STOCK,
                        data: {
                            type: CATALOG_QUERY_BOX_CONTEXT.subscriber,
                            orderLineCount: getters.unavailableBoxItems?.length,
                        },
                    }, { root: true });
                } else if (productToUpdate && !productToUpdate.availableToRent && quantity) {
                    commit(UPDATE_BOX_ITEM_QUANTITY, { skuId, availableToRent: true });
                    productToUpdate.availableToRent = true;
                }
            });
        },
        async [CHECK_FOR_DROP_OFF]({ dispatch, rootGetters }) {
            if (rootGetters[`${PROFILE_MODULE_NAME}/isNonSubscriber`]) return Promise.resolve();

            dispatch(GLOBAL_EVENT, {
                type: CHECK_CARRIER_STATUS,
                data: {
                    action: 'check_carrier_status',
                },
            }, { root: true });

            return checkForDropOff(this.$r15Svc)
                .then((res) => {

                    dispatch(GLOBAL_EVENT, {
                        type: CHECK_CARRIER_SUCCESS,
                        data: {
                            action: 'check_carrier_success',
                        },
                    }, { root: true });

                    return Promise.resolve(res);
                })
                .catch((error) => {

                    dispatch(GLOBAL_EVENT, {
                        type: CHECK_CARRIER_FAILURE,
                        data: {
                            action: 'check_carrier_failure',
                        },
                    }, { root: true });

                    this.$logger.debugError('Failed check if user\'s box was dropped off: ', error);
                    return Promise.reject(error);
                });
        },
        [PREVIEW_BOX_ORDER]({ commit }, boxId) {
            return previewBoxOrder(this.$r15Svc, boxId)
                .then((res) => {
                    commit(SET_BOX_ORDER_PREVIEW, res);
                })
                .catch((error) => {
                    this.$logger.debugError('Failed to preview the user\'s box order: ', error);
                    return Promise.reject(error);
                });
        },
    },

    getters: {
        /* determines if we should show boxed items in our box modal */
        showBoxedItems(state, getters, rootState, rootGetters) {
            const { boxIsOpen } = getters;
            const isSubscribed = rootGetters[`${PROFILE_MODULE_NAME}/isSubscribed`];

            return isSubscribed && boxIsOpen;
        },
        /* determines if we should show recently ordered items in our box modal */
        showOrderedItems(state, getters, rootState, rootGetters) {
            const { boxHasBeenOrdered, boxFailedToOpen } = getters;
            const isSubscribed = rootGetters[`${PROFILE_MODULE_NAME}/isSubscribed`];
            const recentOrderedProducts = get(state, 'recentOrder.products', []);

            return (
                isSubscribed &&
                boxHasBeenOrdered &&
                !isEmpty(recentOrderedProducts) &&
                !boxFailedToOpen
            );
        },
        boxFailedToOpen(state) {
            const boxStatus = state?.boxOrderStatus;
            const boxBalance = state?.boxInfo?.boxBalance;
            return boxBalance > 0 && RETURN_BOX_ORDER_STATUSES.includes(boxStatus);
        },
        /* Minimum number of items needed to make a box ready to order */
        minBoxItemCount(state) {
            return get(state, 'openBox.minQuantity', BOX_MIN_ITEM_LIMIT_DEFAULT);
        },
        /* Max number of items allowed in a box */
        maxBoxItemCount(state) {
            return get(state, 'openBox.maxQuantity', BOX_MAX_ITEM_LIMIT_DEFAULT);
        },
        // eslint-disable-next-line max-len
        /* the current max box capacity to show to the user, this is not reflective of the actual limit the box can reach */
        currentBoxCounterMax(state, getters) {
            const { minBoxItemCount } = getters;

            return state.bonusItemCounter.count + minBoxItemCount;
        },
        /* All items in the box, regardless of availability */
        allBoxItems(state, getters) {
            const { showBoxedItems } = getters;

            return showBoxedItems ? get(state, 'openBox.products', []) : [];
        },
        /* Items in the box that are available */
        availableBoxItems(state, getters) {
            const { allBoxItems } = getters;

            return allBoxItems.filter(product => product.availableToRent);
        },
        /* Items in the box that are not available */
        unavailableBoxItems(state, getters) {
            const { allBoxItems } = getters;

            return allBoxItems.filter(product => !product.availableToRent);
        },
        /* List of recently ordered products */
        recentlyOrderedItems(state) {
            return get(state, 'recentOrder.products', []);
        },
        hasRecentOrder(state) {
            return !!state?.recentOrder.id;
        },
        /* Box is able to have products added to it, but it may or may not have enough items to place an order */
        boxIsOpen(state) {
            const boxStatus = get(state, 'boxOrderStatus', '');

            return boxStatus === BOX_ORDER_STATUSES.OPEN;
        },
        /* Box order has been successfully returned to the DC */
        boxIsReturned(state) {
            const boxStatus = get(state, 'boxOrderStatus', '');

            return boxStatus === BOX_ORDER_STATUSES.RETURNED;
        },
        /* Box order is on the way back to the DC */
        boxIsReturnStarted(state) {
            const boxStatus = get(state, 'boxOrderStatus', '');

            return boxStatus === BOX_ORDER_STATUSES.RETURN_STARTED;
        },
        /* Box order has been delivered to the customer */
        boxIsDelivered(state) {
            const boxStatus = get(state, 'boxOrderStatus', '');

            return boxStatus === BOX_ORDER_STATUSES.DELIVERED;
        },
        /* The active box has been ordered, and is no longer OPEN */
        boxHasBeenOrdered(state) {
            const boxStatus = get(state, 'boxOrderStatus', '');

            return ORDERED_BOX_STATUSES.includes(boxStatus);
        },
        /* The box is either headed to the user or in the user's possession */
        boxIsOutboundOrDelivered(state) {
            const boxStatus = get(state, 'boxOrderStatus', '');
            const {
                SHIPPED,
                IN_TRANSIT,
                DELIVERED,
            } = BOX_ORDER_STATUSES;

            return [SHIPPED, IN_TRANSIT, DELIVERED].includes(boxStatus);
        },
        /* Box is able to have products added to it, but there is currently no products in the box */
        openBoxIsEmpty(state, getters) {
            const { boxIsOpen, allBoxItems } = getters;

            return boxIsOpen && allBoxItems.length === 0;
        },
        /* Box status is OPEN, and has unavailable items it it */
        openBoxHasUnavailableItems(state, getters) {
            const { boxIsOpen, unavailableBoxItems } = getters;

            return boxIsOpen && unavailableBoxItems.length > 0;
        },
        /* Box Status is OPEN, and has the minimum number of items in it to be submittable,
           but may have unavailable items it it */
        openBoxHasMetMinItemLimit(state, getters) {
            const { boxIsOpen, minBoxItemCount, allBoxItems } = getters;

            return boxIsOpen && allBoxItems.length >= minBoxItemCount;
        },
        /* Box Status is OPEN, and has the maximum number of items it can hold,
           but may have unavailable items it it */
        openBoxHasMetMaxItemLimit(state, getters) {
            const { boxIsOpen, currentBoxCounterMax, allBoxItems } = getters;

            return boxIsOpen && allBoxItems.length === currentBoxCounterMax;
        },
        /* Box is submittable: Box status is OPEN, has at least the minumum item count, and has no unavailable items */
        openBoxIsSubmittable(state, getters) {
            const { boxIsOpen, openBoxHasMetMinItemLimit, openBoxHasUnavailableItems } = getters;

            return boxIsOpen && openBoxHasMetMinItemLimit && !openBoxHasUnavailableItems;
        },
        // TODO: implement bonus items once the feature is supported - this was ported from rent maybe can be removed?
        // its not used anywhere
        // openBoxHasBonusItemsApplied() {
        //     return false;
        // },
        /* List of choiceIds in the Box */
        choiceIdsInBox(state, getters) {
            const { allBoxItems } = getters;

            return allBoxItems.map(item => item.choiceId);
        },
        getContentfulInterpolationValues(state, getters, rootState) {
            const { nextBillingCycle: billingDate } = rootState[PROFILE_MODULE_NAME];
            const { pauseCyclesRequested: pauseLength } = rootState[PROFILE_MODULE_NAME];
            const inBoundTrackingUrl = get(state, 'recentOrder.trackingInfo.0.inbound.url', '');
            const outboundTrackingUrl = get(state, 'recentOrder.trackingInfo.0.outbound.url', '');

            return (ctx) => {
                const formattedDate = billingDate && ctx && ctx.$d ?
                    ctx.$d(new Date(billingDate), 'yearless') : '';

                return {
                    billingDate: formattedDate,
                    pauseLength,
                    inBoundTrackingUrl,
                    outboundTrackingUrl,
                };
            };
        },
        /* the number of bonus items currently in the box */
        bonusItemsInBoxCount(state, getters) {
            const { allBoxItems, boxIsOpen, minBoxItemCount } = getters;

            return boxIsOpen ? Math.max(0, allBoxItems.length - minBoxItemCount) : 0;
        },
        /* number of bonus items that can be added to the box with no charge */
        availableFreeBonusItemCount(state, getters) {
            const { boxIsOpen, minBoxItemCount } = getters;
            return boxIsOpen ? Math.max(0, get(state, 'openBox.availableQuantity', 0) - minBoxItemCount) : 0;
        },
        /* number of free bonus items that you haven't used */
        unusedFreeBonusItems(state, getters) {
            const { availableFreeBonusItemCount } = getters;
            const bonusItemCounter = state.bonusItemCounter.count;
            const noFreeBonusItemLeft = availableFreeBonusItemCount === 0 ||
                availableFreeBonusItemCount < bonusItemCounter;

            return noFreeBonusItemLeft ? 0 : availableFreeBonusItemCount - bonusItemCounter;
        },
        /* min number of bonus items that be applied to the BoxBonusItemCounter */
        minBonusItemCount(state, getters) {
            const { bonusItemsInBoxCount, boxIsOpen } = getters;

            if (!boxIsOpen) {
                return 0;
            }

            return bonusItemsInBoxCount >= 0 ? bonusItemsInBoxCount : 0;
        },
        /* max number of bonus items that be applied to the BoxBonusItemCounter */
        maxBonusItemCount(state, getters) {
            const { boxIsOpen, minBoxItemCount, maxBoxItemCount } = getters;

            return boxIsOpen ? Math.max(0, maxBoxItemCount - minBoxItemCount) : 0;
        },

        /* number that wil be used for the box counter if the user has not set otherwise */
        defaultBonusItemCount(state, getters) {
            const { boxIsOpen, bonusItemsInBoxCount, availableFreeBonusItemCount } = getters;

            return boxIsOpen ? Math.max(bonusItemsInBoxCount, availableFreeBonusItemCount) : 0;
        },
        unreviewedRecentlyOrderedSkus(state, getters) {
            const { recentlyOrderedItems } = getters;
            const skus = [];

            recentlyOrderedItems.forEach((recentlyOrderedItem) => {
                if (!get(recentlyOrderedItem, 'overallRating')) {
                    skus.push(recentlyOrderedItem.skuId);
                }
            });

            return skus;
        },
        boxIsDeliveredOrReturnStarted(state) {
            const boxStatus = get(state, 'boxOrderStatus', '');
            const {
                RETURN_STARTED,
                DELIVERED,
            } = BOX_ORDER_STATUSES;

            return [RETURN_STARTED, DELIVERED].includes(boxStatus);
        },
        shippedOrdersInBoxHistory(state) {
            const { orderHistory } = state;

            return orderHistory.filter(e => RETURNABLE_BOX_ORDER_STATUSES.includes(e.status));
        },
        shippedRecentlyOrderedSkus(state, getters) {
            const { recentlyOrderedItems } = getters;
            const skus = [];

            recentlyOrderedItems.forEach((recentlyOrderedItem) => {
                if (get(recentlyOrderedItem, 'orderData.status') === ORDER_ITEM_STATUSES.SHIPPED) {
                    skus.push(recentlyOrderedItem.skuId);
                }
            });

            return skus;
        },
        purchasedRecentlyOrderedSkus(state, getters) {
            const { recentlyOrderedItems } = getters;
            const skus = [];

            recentlyOrderedItems.forEach((recentlyOrderedItem) => {
                if (get(recentlyOrderedItem, 'orderData.status') === ORDER_ITEM_STATUSES.PURCHASED) {
                    skus.push(recentlyOrderedItem.skuId);
                }
            });

            return skus;
        },
        hasBox(state) {
            return get(state, 'boxInfo.hasBox', false);
        },
        disableBoxActions(state, getters, rootState, rootGetters) {
            return rootGetters[`${PROFILE_MODULE_NAME}/isAnonymousOrNonSubscriber`];
        },
    },

    modules: {},
};
